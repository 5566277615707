export const handleGCLID = async (gclid) => {
    storeGCLIDLocally(gclid)
}

export const storeGCLIDLocally = (gclid) => {
    window.localStorage.setItem('gclid', gclid);
}

export const getGCLIDFromLocal = () => {
    return window.localStorage.getItem('gclid');
}