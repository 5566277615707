import HttpService from '../HttpService'

/**
 * Fetches FAQs with optional localization and additional parameters.
 *
 * @param {Object} ctx - The context containing localization data.
 * @param {Object} additionalParams - Additional query parameters.
 * @param {Object} headers - Request headers.
 * @returns {Promise} The response from the FAQ fetch request.
 */
export async function fetchFaqs(ctx, additionalParams, headers) {
    const params = { ...additionalParams, ...(ctx.$i18n?.locale && translate && { lang: ctx.$i18n.locale }) };
    try {
        return HttpService.query()
            .setPath('/view/faqs/')
            .setParams(params)
            .setHeaders(headers)
            .get();
    } catch {
        return { data: null }; // Handle failure gracefully
    }
}

/**
 * Fetches reviews with optional localization.
 *
 * @param {Object} ctx - The context containing localization data.
 * @param {boolean} [translate=true] - Whether to include the language parameter.
 * @returns {Promise} The response from the reviews fetch request.
 */
export async function fetchReviews(ctx, translate = true) {
    const params = { ...(ctx.$i18n?.locale && translate && { lang: ctx.$i18n.locale }) };
    try {
        return HttpService.query().setPath('/view/reviews/').setParams(params).get();
    } catch {
        return { data: null }; // Handle failure gracefully
    }
}

/**
 * Fetches details of an user review from TrustPilot by id
 * @param {*} id 
 * @param {*} translate | Whether to translate the content
 * @returns 
 */
export async function fetchReview(ctx, id, translate) {
    const params = {}
    if (ctx.$i18n && ctx.$i18n.locale && translate) params.lang = ctx.$i18n.locale
    try {
        const { data: info } = await HttpService.query().setPath('/view/reviews/' + id).setParams(params).get()
        return info
    } catch (e) {
    }
}

/**
 * Fetches metadata, either from localStorage or via an API call.
 * 
 * @returns {Promise<Object>} A promise that resolves to the metadata object.
 */
export async function fetchMetadata() {
    try {
        const storedMetadata = window.localStorage.getItem('metadata');
        const parsedMetadata = storedMetadata ? JSON.parse(storedMetadata) : null;

        if (parsedMetadata && parsedMetadata.country) return parsedMetadata;
        
        const {data: fetchedMetadata} = await HttpService.query().setPath('/metadata').get();
        window.localStorage.setItem('metadata', JSON.stringify(fetchedMetadata));
        
        return fetchedMetadata
    } catch {
        return { data: null }; // Handle failure gracefully
    }
}

