import { handleAdTeamDirection } from "./TelemetryEvents"
import { handleGCLID } from "./LTVTracker"
export default class UrlParamHandler {
    constructor(route) {
        this.route = route
    }

    checkIfAdTeamDirection () {
        const campaign = this.route.query.utm_campaign
        if(!campaign) return false
        return campaign.includes('asc')
    }

    checkForGCLID () {
        const campaign = this.route.query.gclid
        return campaign || false
    }

    handle () {
        if (this.checkIfAdTeamDirection()) handleAdTeamDirection()
        if (this.checkForGCLID()) handleGCLID(this.checkForGCLID())
    }
}